import React from 'react';

const LightningOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3487 1.06286C13.7834 1.22458 14.0498 1.66393 13.9923 2.12413L13.1328 9.0001H19C19.3688 9.0001 19.7077 9.20308 19.8817 9.52824C20.0557 9.85339 20.0366 10.2479 19.8321 10.5548L11.8321 22.5548C11.5748 22.9407 11.086 23.099 10.6513 22.9373C10.2166 22.7756 9.9502 22.3363 10.0077 21.8761L10.8672 15.0001H5C4.63121 15.0001 4.29235 14.7971 4.11833 14.472C3.94431 14.1468 3.96338 13.7523 4.16795 13.4454L12.168 1.4454C12.4252 1.05951 12.914 0.901142 13.3487 1.06286ZM6.86852 13.0001H12C12.2868 13.0001 12.5599 13.1233 12.7497 13.3383C12.9395 13.5533 13.0279 13.8395 12.9923 14.1241L12.5177 17.9208L17.1315 11.0001H12C11.7132 11.0001 11.4401 10.8769 11.2503 10.6619C11.0605 10.4469 10.9721 10.1607 11.0077 9.87606L11.4823 6.07942L6.86852 13.0001Z"
      />
    </svg>
  );
};

export default LightningOutlineIcon;
