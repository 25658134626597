import React from 'react';

const LayoutIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2667 4.06699L2.73337 4.06699L2.73337 5.93398L13.2667 5.93399V4.06699ZM1.33337 5.93398V7.33398V12.6003C1.33337 13.3735 1.96017 14.0003 2.73337 14.0003H6.00004H7.40004H13.2667C14.0399 14.0003 14.6667 13.3735 14.6667 12.6003V7.33399V5.93399V4.06699C14.6667 3.29379 14.0399 2.66699 13.2667 2.66699H2.73337C1.96018 2.66699 1.33337 3.29379 1.33337 4.06699V5.93398ZM7.40004 12.6003L13.2667 12.6003L13.2667 7.33399L7.40004 7.33399L7.40004 12.6003ZM6.00004 7.33398L2.73337 7.33398L2.73337 12.6003H6.00004L6.00004 7.33398Z"
      />
    </svg>
  );
};

export default LayoutIcon;
