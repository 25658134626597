import React from 'react';

const TwitterXIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M13.6547 10.4686L20.9451 2H19.2175L12.8873 9.3532L7.8314 2H2L9.64553 13.1193L2 22H3.72767L10.4125 14.2348L15.7519 22H21.5833L13.6543 10.4686H13.6547ZM11.2885 13.2173L10.5138 12.1101L4.35018 3.29968H7.00379L11.9779 10.4099L12.7526 11.5172L19.2183 20.7594H16.5647L11.2885 13.2177V13.2173Z" />
    </svg>
  );
};

export default TwitterXIcon;
