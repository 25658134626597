import React from 'react';

const WarningRhombusOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1652 3.3458C11.6262 2.88473 12.3738 2.88473 12.8348 3.34581L20.6542 11.1652C21.1153 11.6262 21.1153 12.3738 20.6542 12.8348L12.8348 20.6542C12.3738 21.1153 11.6262 21.1153 11.1652 20.6542L3.3458 12.8348C2.88473 12.3738 2.88473 11.6262 3.34581 11.1652L11.1652 3.3458ZM14.2491 1.93159C13.0069 0.689472 10.9931 0.689468 9.75094 1.93159L1.93159 9.75094C0.689472 10.9931 0.689468 13.0069 1.93159 14.2491L9.75094 22.0684C10.9931 23.3105 13.0069 23.3105 14.2491 22.0684L22.0684 14.2491C23.3105 13.0069 23.3105 10.9931 22.0684 9.75094L14.2491 1.93159ZM12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7ZM11 16C11 15.4477 11.4477 15 12 15H12.01C12.5623 15 13.01 15.4477 13.01 16C13.01 16.5523 12.5623 17 12.01 17H12C11.4477 17 11 16.5523 11 16Z"
      />
    </svg>
  );
};

export default WarningRhombusOutlineIcon;
