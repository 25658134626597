import React from 'react';

const PencilOutlineIcon = (props) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 4C18.1015 4 17.7196 4.15803 17.4384 4.43867L17.4384 4.43871L16.6476 5.2278L18.768 7.34388L19.5616 6.55191C19.8428 6.27133 20.0004 5.89122 20.0004 5.49529C20.0004 5.09937 19.8428 4.71925 19.5616 4.43867C19.2804 4.15803 18.8986 4 18.5 4ZM19.4844 9.45441L20.9744 7.96759L20.9744 7.96755C21.6311 7.31221 22.0004 6.42296 22.0004 5.49529C22.0004 4.56763 21.6311 3.67837 20.9744 3.02304C20.3178 2.36776 19.4277 2 18.5 2C17.5723 2 16.6823 2.36775 16.0257 3.023C16.0257 3.02301 16.0256 3.02302 16.0256 3.02304L4.2301 14.7938C4.12399 14.8996 4.04305 15.028 3.99325 15.1694L2.05681 20.6678C1.92858 21.0319 2.02137 21.4373 2.29522 21.7094C2.56906 21.9815 2.97506 22.0716 3.33832 21.941L8.80716 19.975C8.9455 19.9253 9.07117 19.8457 9.17523 19.7419L19.4642 9.47463C19.4681 9.47083 19.472 9.46699 19.4758 9.4631C19.4787 9.46022 19.4816 9.45732 19.4844 9.45441ZM17.3523 8.75661L15.2319 6.64052L5.80403 16.0486L4.64242 19.3469L7.92051 18.1685L17.3523 8.75661Z"
      />
    </svg>
  );
};

export default PencilOutlineIcon;
