// @ts-check
import React from 'react';

const TOPhoneIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path
        d="M16.4999 13.1901V15.4401C16.5008 15.6489 16.458 15.8557 16.3743 16.0471C16.2907 16.2385 16.1679 16.4103 16.014 16.5515C15.8601 16.6927 15.6784 16.8002 15.4805 16.8671C15.2826 16.934 15.073 16.9589 14.8649 16.9401C12.5571 16.6893 10.3402 15.9007 8.39245 14.6376C6.58032 13.4861 5.04395 11.9497 3.89245 10.1376C2.62493 8.18098 1.83613 5.95332 1.58995 3.63507C1.57121 3.42767 1.59586 3.21864 1.66233 3.02129C1.72879 2.82394 1.83563 2.64259 1.97602 2.48879C2.11642 2.33499 2.2873 2.2121 2.47779 2.12796C2.66828 2.04382 2.87421 2.00027 3.08245 2.00007H5.33245C5.69643 1.99649 6.04929 2.12538 6.32527 2.36272C6.60125 2.60006 6.78151 2.92966 6.83245 3.29007C6.92742 4.01012 7.10354 4.71712 7.35745 5.39757C7.45836 5.66602 7.4802 5.95776 7.42038 6.23823C7.36056 6.51871 7.2216 6.77616 7.01995 6.98007L6.06745 7.93257C7.13512 9.81023 8.68979 11.3649 10.5675 12.4326L11.5199 11.4801C11.7239 11.2784 11.9813 11.1395 12.2618 11.0796C12.5423 11.0198 12.834 11.0417 13.1024 11.1426C13.7829 11.3965 14.4899 11.5726 15.21 11.6676C15.5743 11.719 15.907 11.9025 16.1448 12.1832C16.3827 12.4639 16.5091 12.8223 16.4999 13.1901Z"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TOPhoneIcon;
