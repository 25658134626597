// @ts-check
import React from 'react';

const StampOutlineIcon = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.48591 4.27797C9.85664 3.6399 10.5358 3 12.0002 3C12.8654 3 13.4321 3.22358 13.8162 3.49716C14.2091 3.77695 14.4773 4.15694 14.6608 4.56296C14.9994 5.31198 14.7943 6.23285 14.4026 7.50184C14.0051 8.78925 13.5266 10.679 13.2438 13H11.198C10.8735 10.8532 10.3407 9.07218 9.86879 7.78952C9.60124 7.06236 9.36957 6.39671 9.27937 5.77034C9.19161 5.16088 9.25239 4.67989 9.48591 4.27797ZM9.17347 13C8.86996 11.1438 8.4041 9.60065 7.99181 8.48014C7.73121 7.77188 7.42417 6.91916 7.29979 6.05539C7.17298 5.17471 7.22175 4.19379 7.75661 3.27323C8.45479 2.07157 9.76444 1 12.0002 1C13.2358 1 14.2183 1.32809 14.9765 1.86811C15.726 2.40192 16.1932 3.09746 16.4833 3.73917C17.1809 5.28252 16.6665 6.9488 16.3153 8.08612L16.3135 8.09186C15.9583 9.24249 15.5284 10.9281 15.2595 13H19C20.1046 13 21 13.8954 21 15V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V15C3 13.8954 3.89543 13 5 13H9.17347ZM9.43434 15H5V17H19V15H15.0518H14.1415H10.3236H9.43434ZM5 21C5 20.4477 5.44772 20 6 20H18C18.5523 20 19 20.4477 19 21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21Z"
      />
    </svg>
  );
};

export default StampOutlineIcon;
