// @ts-check
import React from 'react';

const SmartPhoneIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C6 3.44772 6.44772 3 7 3H17C17.5523 3 18 3.44772 18 4V20C18 20.5523 17.5523 21 17 21H7C6.44772 21 6 20.5523 6 20V4ZM7 1C5.34315 1 4 2.34315 4 4V20C4 21.6569 5.34315 23 7 23H17C18.6569 23 20 21.6569 20 20V4C20 2.34315 18.6569 1 17 1H7ZM12 17C11.4477 17 11 17.4477 11 18C11 18.5523 11.4477 19 12 19H12.01C12.5623 19 13.01 18.5523 13.01 18C13.01 17.4477 12.5623 17 12.01 17H12Z"
      />
    </svg>
  );
};

export default SmartPhoneIcon;
