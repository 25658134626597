// @ts-check
import React from 'react';

const TOGlogalIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50146 9.46904C3.56154 6.81021 5.47542 4.60906 8.00131 4.10737C7.97688 4.13304 7.95304 4.15926 7.92979 4.18595C7.63241 4.52736 7.38734 4.99244 7.19154 5.52389C6.80931 6.56138 6.57831 7.95228 6.56589 9.46904H3.50146ZM3.56737 10.469C3.92959 12.7874 5.71464 14.6343 8.00124 15.0885C7.97683 15.0629 7.95301 15.0367 7.92979 15.01C7.63241 14.6686 7.38734 14.2035 7.19154 13.6721C6.8726 12.8064 6.65896 11.6946 6.58973 10.469H3.56737ZM7.59143 10.469C7.65943 11.5924 7.85612 12.5833 8.12989 13.3264C8.30193 13.7934 8.49486 14.1362 8.68384 14.3532C8.87383 14.5713 9.01526 14.6152 9.09802 14.6152C9.18077 14.6152 9.3222 14.5713 9.51219 14.3532C9.70117 14.1362 9.89411 13.7934 10.0661 13.3264C10.3399 12.5833 10.5366 11.5924 10.6046 10.469H7.59143ZM11.6063 10.469C11.5371 11.6946 11.3234 12.8064 11.0045 13.6721C10.8087 14.2035 10.5636 14.6686 10.2662 15.01C10.243 15.0367 10.2192 15.0629 10.1948 15.0885C12.4813 14.6343 14.2663 12.7874 14.6285 10.469H11.6063ZM14.6945 9.46904H11.6301C11.6177 7.95228 11.3867 6.56138 11.0045 5.52389C10.8087 4.99244 10.5636 4.52736 10.2662 4.18595C10.243 4.15927 10.2192 4.13306 10.1948 4.1074C12.7206 4.60914 14.6344 6.81027 14.6945 9.46904ZM10.6301 9.46904H7.56593C7.57839 8.04123 7.79723 6.7725 8.12989 5.86959C8.30193 5.40262 8.49486 5.05973 8.68384 4.84277C8.87383 4.62465 9.01526 4.58079 9.09802 4.58079C9.18077 4.58079 9.3222 4.62465 9.51219 4.84277C9.70117 5.05973 9.89411 5.40262 10.0661 5.86959C10.3988 6.7725 10.6176 8.04123 10.6301 9.46904ZM2.5 9.59796C2.5 5.95401 5.45401 3 9.09796 3C12.7419 3 15.6959 5.95401 15.6959 9.59796C15.6959 13.2419 12.7419 16.1959 9.09796 16.1959C5.45401 16.1959 2.5 13.2419 2.5 9.59796Z"
      />
    </svg>
  );
};

export default TOGlogalIcon;
