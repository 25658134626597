// @ts-check
import React from 'react';

const TOEmailIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 3C1.89543 3 1 3.89543 1 5V14C1 15.1046 1.89543 16 3 16H15C16.1046 16 17 15.1046 17 14V5C17 3.89543 16.1046 3 15 3H3ZM2 5C2 4.44772 2.44772 4 3 4H15C15.5523 4 16 4.44771 16 5V14C16 14.5523 15.5523 15 15 15H3C2.44771 15 2 14.5523 2 14V5ZM3.22361 5.30279C2.97662 5.17929 2.67628 5.2794 2.55279 5.52639C2.42929 5.77338 2.5294 6.07372 2.77639 6.19721L8.77639 9.19721C8.91716 9.2676 9.08284 9.2676 9.22361 9.19721L15.2236 6.19721C15.4706 6.07372 15.5707 5.77338 15.4472 5.52639C15.3237 5.2794 15.0234 5.17929 14.7764 5.30279L9 8.19098L3.22361 5.30279Z"
      />
    </svg>
  );
};

export default TOEmailIcon;
